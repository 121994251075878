import React, { useEffect } from 'react'
import { usePaystackPayment } from 'react-paystack'

const Paystack = ({ email, amount, reference }) => {
    const paystackConfig = {
        email,
        amount,
        publicKey: 'pk_live_71e80eae51ca6c303a51583fed02e6ffa70d8362',
        reference: reference
    }
    console.log(paystackConfig)
    const onSuccess = (reference) => {
        const data = {
            reference: reference.trxref,
            // coins_packs: selected_pack.id,
            platform: "web",
            processor: 'paystack'
        }
        console.log(data)
        window.location.reload();

    };

    // you can call this function anything
    const onClose = () => {
         window.location.reload()
    }
    const initializePayment = usePaystackPayment(paystackConfig);
 

    return (
        <button
            className='btn get-ticket-btn w-100 py-3'
            onClick={(e) => {
                 
                e.preventDefault()


                // makePayment();
                initializePayment(onSuccess, onClose)

                // getRSVPTicket(selectedEvent._id)

                // onClick={() => {
                //     initializePayment(onSuccess, onClose)
                // }}
            }}
        // disabled={paystackConfig.email===''}
        >
            {`Confirm Payment of ${amount / 100}`}
        </button>
    )
}

export default Paystack