import { useQuery } from 'react-query';
import Client from '../index';
import Axios from '../../Utils/axios';

// Fetch all events
export const useGetAllEventsQuery = () => {
    const { data, isLoading, error } = useQuery('all_events', () => Client.events.all(), {
        onSuccess: (data) => {
            console.log('All Events fetched successfully:', data);
        },
        onError: (err) => {
            console.error('Error fetching all events:', err);
            if (err.response) {
                console.log('Error Response:', err.response);
            }
        }
    });

    return {
        allEvents: data?.data?.data?.allEvents || [], // Safely access nested properties
        error: error || null,
        isLoading
    };
};

// Fetch paginated events
export const useGetAllEventsPaginatedQuery = (page, usersPerPage) => {
    const { data, isLoading, error } = useQuery(['all_events', page, usersPerPage], async () => {
        const response = await Axios.get(`/event/all?page=${page}&pageSize=${usersPerPage}`);
        return response.data;
    }, {
        keepPreviousData: true, // Keep previous data while loading new data
        onSuccess: (data) => {
            console.log('Paginated Events fetched successfully:', data);
        },
        onError: (err) => {
            console.error('Error fetching paginated events:', err);
            if (err.response) {
                console.log('Error Response:', err.response);
            }
        }
    });

    return {
        pageTotal: data?.data?.pagination?.pageTotal || 0, // Check for pagination data
        allEvents: data?.data?.allEvents || [],
        error: error || null,
        isLoading
    };
};

// Fetch featured events
export const useGetFeaturedEventQuery = () => {
    const { data, isLoading, error } = useQuery('featured_events', () => Client.events.featured(), {
        onSuccess: (data) => {
            console.log('Featured Events fetched successfully:', data);
        },
        onError: (err) => {
            console.error('Error fetching featured events:', err);
            if (err.response) {
                console.log('Error Response:', err.response);
            }
        }
    });

    return {
        featuredEvents: data?.data?.data?.allEvents || [], // Safely access nested properties
        error: error || null,
        isLoading
    };
};

// Fetch hero event
export const useGetHeroEventQuery = () => {
    const { data, isLoading, error } = useQuery('hero_event', () => Client.events.hero(), {
        onSuccess: (data) => {
            console.log('Hero Event fetched successfully:', data);
        },
        onError: (err) => {
            console.error('Error fetching hero event:', err);
            if (err.response) {
                console.log('Error Response:', err.response);
            }
        }
    });

    return {
        heroEvent: data?.data?.data || null, // Safely access nested properties
        error: error || null,
        isLoading
    };
};

// Fetch upcoming events
export const useUpcomingEventQuery = () => {
    const { data, isLoading, error } = useQuery('upcoming_event', () => Client.events.upcoming(), {
        onSuccess: (data) => {
            console.log('Upcoming Events fetched successfully:', data);
        },
        onError: (err) => {
            console.error('Error fetching upcoming events:', err);
            if (err.response) {
                console.log('Error Response:', err.response);
            }
        }
    });

    return {
        upcomingEvents: data?.data?.data?.allEvents || [], // Safely access nested properties
        error: error || null,
        isLoading
    };
};

// Payment controller function
export const paymentController = async (data) => {
    try {
        const response = await Axios.post(`/tickets/rsvp?deviceType=web`, data);
        return response?.data; // Safely return response data
    } catch (error) {
        console.error('Payment Error:', error);
        throw new Error(`Sorry: ${error.response?.data?.message || 'An error occurred'}`);
    }
};

// Get announcements function
export const getAnnouncement = async (data) => {
    try {
        const response = await Axios.post(`/announcement/all`, data);
        return response?.data; // Safely return response data
    } catch (error) {
        console.error('Announcement Error:', error);
        throw new Error(`Sorry: ${error.response?.data?.message || 'An error occurred'}`);
    }
};
