import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import reportWebVitals from './reportWebVitals'
// import store from './Redux/store'
import { QueryClient, QueryClientProvider } from 'react-query'; 

const queryClient = new QueryClient( );
ReactDOM.render(
   
  <QueryClientProvider client={queryClient}>
   
    <App />
     
    </QueryClientProvider>, 
  document.getElementById('root')
) 
/* reportWebVitals() */
