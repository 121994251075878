import axios from 'axios';

const BASE_URL = 'https://api.tixrush.com/api/v1';
let apiKey = null;
let isFetchingApiKey = false; // Flag to track if an API key fetch is in progress

// Function to fetch the apiKey if it's not already fetched
const getApiKey = async () => {
    // Only fetch the API key if it's not already set and no fetch is in progress
    if (!apiKey && !isFetchingApiKey) {
        isFetchingApiKey = true; // Prevent concurrent API key fetches
        try {
            const data = {
                name: 'Deniyi Femi',
                email: 'holarfemilekan049@gmail.com',
            };
            const response = await axios.post(`${BASE_URL}/apiKey/generate`, data);
            apiKey = response.data.data.key;
        } catch (err) {
            // Handle errors
            console.error('Error fetching apiKey:', err.message);
            throw new Error('Failed to fetch API key. Please try again later.');
        } finally {
            isFetchingApiKey = false; // Reset the flag after API key fetch attempt
        }
    }
    return apiKey;
};

// Create an Axios instance
const AxiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add a request interceptor to include the API key in headers
AxiosInstance.interceptors.request.use(async (config) => {
    try {
        const apikey = await getApiKey();
        if (apikey) {
            config.headers['x-api-key'] = apikey;
        } else {
            throw new Error('API key is not available.');
        }
    } catch (error) {
        console.error('Error in request interceptor:', error.message);
        // Optionally, you can reject the request to prevent it from proceeding
        return Promise.reject(error);
    }
    return config;
}, (error) => {
    // Handle any request errors
    return Promise.reject(error);
});

export default AxiosInstance;
